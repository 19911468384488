import { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import GenericError from "~/components/common/generic-error";
import { usePageTracking } from "~/contexts/page-trackings";
import Contentful from "~/services/contentful";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import translationUtils from "~/utils/translation-utils";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function Custom500(props: Props) {
  usePageTracking("error");
  return <GenericError {...props} />;
}

export async function getStaticProps(context: GetStaticPropsContext) {
  if (context.locale === context.defaultLocale) {
    context.locale = Constants.DEFAULT_FALLBACK_LOCALE;
  }

  const contentful = new Contentful({ preview: false, locale: contentfulUtils.mapLocale(context.locale) });

  // Translations
  const messagesPromise = contentful.getEntriesByContentType("translationLabel", Infinity).then((labels) => {
    return translationUtils.parseMultipleTranslationLabels(labels);
  });

  // Footer
  const footerPromise = contentful.getEntriesByContentType("footer", 1).then((c) => c.items[0]);

  const [messages, footer] = await Promise.all([messagesPromise, footerPromise]);

  return {
    props: {
      messages,
      footer,
    },
    revalidate: Constants.DEFAULT_STATIC_PAGE_TIME,
  };
}
